import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
    h1: {
        fontSize: '48px',
        lineHeight: '58px',
    },
    h2: {
        fontSize: '32px',
        lineHeight: '39px',
    },
    h3: {
        fontSize: '24px',
        lineHeight: '29px',
    },
    large: {
        fontSize: '18px',
        lineHeight: '22px',
    },
    body: {
        fontSize: '16px',
        lineHeight: '20px',
    },
    small: {
        fontSize: '14px',
        lineHeight: '17px',
    },
    mini: {
        fontSize: '12px',
        lineHeight: '15px',
    },
    tiny: {
        fontSize: '10px',
        lineHeight: '12px',
    },
    fontFamily: ['Inter', 'sans-serif'].join(','),
};
